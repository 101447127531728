#app {max-width:1200px; margin:0 auto;}
#main {}
#header_title { font-size:1.2em; }
/* calendar must have a defined height to show. Taking half the screen by default. */
#calendar { height: 75vh; width: 70vw; overflow-y: scroll;}
/* #calendar { display: flex; flex-grow: 1; height: 0; } */
#calendarx { height: 70vh; }
#event_details {background-color:#eee;}
a.title-link { cursor: pointer; user-select: none; text-decoration: none; }
.badge { margin-left:3px; }
.nav-link.active { font-weight: bold; }

/* .sidebar-item { margin-left: 2em; } */
.sidebar-item { text-align: center; margin: 0 0 1em .5em; }
a.sidebar-link { cursor: pointer; user-select: none; text-decoration: underline; }
.sidebar-friend-calendars { display: block; text-align: center; margin-top:1.5em; }
.sidebar-friend-label { font-weight:600; }

.program-dates { display: block; margin-left: 1em; margin-bottom: 1.3em; font-size: .9em; }
.program-form-label { font-weight: 600; }
.program-friends-attending { display:block; margin-bottom: .4em; font-weight: 600; }

.form-hardcoded { display: block;margin-left:.5em;}

.rbc-event { font-size:.8em }

li.li-padding { margin-bottom: .6em; }

.notifications { display:inline-block; margin-left:.3em; color:red; font-weight:bold; }
.awaiting-response { display:inline-block; padding:0 1em;color:#666; font-style:italic; }
.notify-child-h3 {margin-top:1em;margin-bottom:.5em;}
.notify-friend-name { font-weight:600; margin-left:1em; font-size:1.2em; }

.friend-cal { display: block; width: 100%; }
.friend-cal-name { display: inline-block; width: 20vw; font-weight:600;font-size:1.2em; }
.friend-cal-school { display: inline-block; width: 20vw; }
.friend-cal-programs { display: inline-block; width: 10vw; }

.weekly-week-range { display: inline-block; width: 100%; font-weight:600;font-size:1.2em; }

.landing-image { display: block; object-fit: contain; max-width:100%; height:auto; }
.landing-box { display: block; text-align: center; color: #777; font-weight: 600; font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }

.bottom-bar { display: block; text-align: center; width: 100%; padding: 1em 0; }
.report-error-link, .report-error-link a { color: #555; font-size: .9em; text-decoration: underline; cursor: pointer; user-select: none; }

.date-wrap { font-weight: 500; font-size: 1.2em; margin-top: .5em; margin-bottom: -.5em; padding: .3em 0 0 .3em; background-color: #eee; }
.line { border-top: 1px solid #ccc; margin: .5em 0 .5em 0; width: 90%; }
.agenda-event { border-top: 1px solid #ccc; }
.agenda-event-title { display: block; padding-left: 1em; margin: .5em 0; cursor: pointer; }
.agenda-event-time { display: block; padding-left:2em; font-size: .9em;padding-bottom:.5em; }
.agenda-event-details { display: block; padding-left: 3em; }